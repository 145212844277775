
/* @font-face {
  font-family: 'Poppins';
  src: url('https://res-front.pumpsoul.com/font/Poppins-Regular.otf') format('opentype');
  font-weight: regular;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('https://res-front.pumpsoul.com/font/Poppins-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('https://res-front.pumpsoul.com/font/Poppins-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
} */

:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: 'Poppins', ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
    'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro', 'Fira Mono',
    'Droid Sans Mono', 'Courier New', monospace;
  --primary-glow: conic-gradient(from 180deg at 50% 50%, #16abff33 0deg, #0885ff33 55deg, #54d6ff33 120deg, #0071ff33 160deg, transparent 360deg);
  --secondary-glow: radial-gradient(#ffffff, rgba(255, 255, 255, 0));
  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(#00000080, #00000040, #00000030, #00000020, #00000010, #00000010, #00000080);
  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
  --pietra-yellow: #f9e070;
  --app-link-hover: #ff401a;
  --pietra-pink: #fbf2f0;
  --off-white: #fafafa;
  --pietra-global-header-heigth: 64px;
  --pietra-global-mobile-header-heigth: 64px;
  --search-input-height: 40px;
}
:root .ant-select-selection {
  touch-action: manipulation;
  /* 通过修改触摸行为来防止默认放大 */
  -ms-touch-action: manipulation;
  /* 兼容IE */
}
:root ul {
  list-style-type: none;
}
:root .next-antd-pagination-options {
  display: none !important;
}
:root .link:hover {
  color: var(--app-primary-color);
}
@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(to bottom right, rgba(1, 65, 255, 0), rgba(1, 65, 255, 0), rgba(1, 65, 255, 0.3));
    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(#ffffff80, #ffffff40, #ffffff30, #ffffff20, #ffffff10, #ffffff10, #ffffff80);
    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  font-family: 'Poppins', sans-serif;
}
body {
  padding: 0;
  margin: 0 auto;
  color: #000;
  background: #fff;
}
a {
  color: inherit;
  text-decoration: none;
}
@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}
.utility-big {
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  line-height: 18px;
}
.utility-small {
  color: #141414;
  font-family: Poppins;
  font-size: 12px;
  line-height: 18px;
}
@media (min-width: 320px) {
  /* Used for large headlines and branded moments. */
  .xl-headline,
  h1,
  .h1 {
    color: #000;
    font-family: Poppins;
    font-size: 42px;
    line-height: 100%;
    font-weight: normal;
  }
  /* Used for medium headlines and branded moments. */
  h2,
  .h2 {
    color: #000;
    font-family: Poppins;
    font-size: 34px;
    line-height: 100%;
    font-weight: normal;
  }
  /* Used for introductory text, such as a blog post or editorial page. */
  h3,
  .h3 {
    color: #141414;
    font-family: Poppins;
    font-size: 22px;
    line-height: 100%;
    font-weight: normal;
  }
  /* Our main body text and is used for long blocks of copy such as blog posts and descriptions,
     as well as product descriptions within a PDP. */
  .body-text {
    color: #141414;
    font-family: Poppins;
    font-size: 17px;
    line-height: 22px;
  }
  .body-title {
    color: #141414;
    font-family: Poppins;
    font-size: 17px;
    line-height: 22px;
  }
  /* This text is our main body text and is used for long blocks of copy such as blog posts and
     descriptions, as well as product descriptions within a PDP. */
  body,
  html,
  .body-text-small {
    color: #141414;
    font-family: Poppins !important;
    font-size: 15px !important;
    line-height: 20px !important;
    font-feature-settings: 'calt' off !important;
  }
}
@media (min-width: 768px) {
  /* Used for the headline on the homepage. */
  .xl-headline {
    font-family: Poppins;
    font-size: 75px;
    line-height: 100%;
    color: #000;
  }
  /* Used for large headlines and branded moments. */
  h1,
  .h1 {
    color: #141414;
    font-family: Poppins;
    font-size: 60px;
    line-height: 100%;
  }
  /* Used for medium headlines and branded moments. */
  h2,
  .h2 {
    color: #000;
    font-family: Poppins;
    font-size: 42px;
    line-height: 100%;
  }
  /* Used for introductory text, such as a blog post or editorial page. */
  h3,
  .h3 {
    color: #000;
    font-family: Poppins;
    font-size: 24px;
    line-height: 100%;
  }
  /* This text is used with short blocks of copy or when larger body copy is needed. */
  .body-text {
    color: #141414;
    font-family: Poppins;
    font-size: 15px;
    line-height: 20px;
  }
  /* This text is our main body text and is used for long blocks of copy such as blog posts
     and descriptions, as well as product descriptions within a PDP. */
  body,
  html,
  .body-text-small {
    color: #141414;
    font-family: Poppins !important;
    font-size: 16px !important;
    line-height: 18px;
    font-feature-settings: 'calt' off !important;
  }
}

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #29d;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
  opacity: 1.0;
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #29d;
  border-left-color: #29d;
  border-radius: 50%;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}
@keyframes nprogress-spinner {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.index_content__zf_z1 {
  display: flex;
  flex-direction: column;
  padding: 52px 28px 44px 28px;
}
.index_content__zf_z1 .index_tabs__qr09E {
  position: relative;
  display: flex;
  width: 276px;
  height: 42px;
  background: #e7f2e9;
  margin: 0 auto;
  border-radius: 42px;
}
.index_content__zf_z1 .index_tabs__qr09E .index_tabsTip__kUSKo {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
  font-size: 14px;
  height: 28px;
  line-height: 28px;
  background: #ed8133;
  padding: 0 12px;
  border-radius: 28px;
  transform: translate(40%, -60%);
  color: #fff;
}
.index_content__zf_z1 .index_tabs__qr09E .index_tab__bU4nM {
  position: relative;
  flex: 1 1;
  line-height: 42px;
  text-align: center;
  border-radius: 42px;
  font-size: 14px;
  color: var(--app-primary-color);
  font-weight: 600;
}
.index_content__zf_z1 .index_tabs__qr09E .index_tab__bU4nM span {
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 99;
  cursor: pointer;
  transition: all 0.6s ease-in-out;
}
.index_content__zf_z1 .index_tabs__qr09E .index_activeTab__Olcxu {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: var(--app-primary-color);
  border-radius: 42px;
  transition: all 0.3s ease-in-out;
}
.index_content__zf_z1 .index_title__oklsf {
  width: 265px;
  text-align: center;
  margin: 0 auto;
  margin-top: 24px;
}
.index_content__zf_z1 .index_title__oklsf h3 {
  margin-bottom: 17px;
  font-weight: 600;
  font-size: 24px;
  height: 48px;
}
.index_content__zf_z1 .index_title__oklsf .index_price__81B9D {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 20px;
}
.index_content__zf_z1 .index_title__oklsf .index_price__81B9D .index_currentPrice__s74rL {
  font-size: 46px;
  font-weight: 600;
  line-height: 1;
}
.index_content__zf_z1 .index_title__oklsf .index_price__81B9D .index_originalPrice__btRho {
  color: #c0c0c0;
  vertical-align: bottom;
  margin-left: 5px;
  text-decoration: line-through;
  -webkit-text-decoration-color: rgba(192, 192, 192);
  text-decoration-color: rgba(192, 192, 192);
  font-size: 20px;
}
.index_content__zf_z1 .index_rights__QCkQR {
  width: 100%;
  height: 286px;
  background: #e7f2e9;
  border-radius: 6px;
  padding: 24px;
  margin-top: 20px;
}
.index_content__zf_z1 .index_rights__QCkQR .index_rightsItem__a6hbu {
  display: flex;
  margin-bottom: 24px;
  align-items: flex-start;
  line-height: 1.5;
}
.index_content__zf_z1 .index_rights__QCkQR .index_rightsItem__a6hbu .index_rightsIcon__RZOjC {
  font-size: 18px;
  color: var(--app-primary-color);
  stroke-width: 10px;
  padding-top: 5px;
}
.index_content__zf_z1 .index_rights__QCkQR .index_rightsItem__a6hbu .index_rightsText__mW7vZ {
  margin-left: 21px;
  color: var(--app-text-60-color);
  font-size: 16px;
}
.index_content__zf_z1 .index_payBtn__Ivt9a {
  margin-top: 30px;
  height: 54px;
  background-color: var(--app-primary-color);
}

.index_content__JWMCp {
  padding: 24px;
  border-radius: 5px;
  overflow: hidden;
  background-image: url('https://res-front.pumpsoul.com/pb-prod/image/create_top_bottom_bg.webp');
  background-size: auto 100%;
  background-position: top center;
  background-repeat: no-repeat;
}
.index_content__JWMCp > .index_topTitle__D_aut {
  font-size: 26px;
  color: var(--app-text-color);
}
.index_content__JWMCp > .index_topSubTitle__K9UC0 {
  margin-top: 32px;
  font-size: 20px;
  font-weight: 500;
  color: var(--app-text-color);
}
.index_content__JWMCp > .index_topDesc__dKgh5 {
  margin-top: 15px;
  font-size: 16px;
  font-weight: 400;
  color: var(--app-text-color);
}
.index_content__JWMCp > .index_infoContent__8CXTq {
  padding: 20px 0;
}
.index_content__JWMCp > .index_infoContent__8CXTq .index_inputPlaceholder__Ytg_r,
.index_content__JWMCp > .index_infoContent__8CXTq .index_selectContent__jLpBT {
  min-width: 70%;
  margin-bottom: 20px;
}
.index_content__JWMCp > .index_infoContent__8CXTq .index_inputPlaceholder__Ytg_r:last-child,
.index_content__JWMCp > .index_infoContent__8CXTq .index_selectContent__jLpBT:last-child {
  margin-bottom: 0;
}
.index_content__JWMCp > .index_infoContent__8CXTq .index_radioContent__CcVAR .index_radioContainer__Yzpca {
  margin-bottom: 20px;
}
.index_content__JWMCp > .index_infoContent__8CXTq .index_radioContent__CcVAR .index_textArea__YBOEk {
  padding: 16px;
  resize: none;
  height: 172px;
}
.index_content__JWMCp > .index_infoContent__8CXTq .index_active__NeB2B::placeholder {
  color: var(--app-text-color);
}
.index_content__JWMCp > .index_infoContent__8CXTq .index_bottomSelectContent__pXnDm {
  background-color: #eee;
  padding: 5px;
  border-radius: 5px;
  left: 0;
  width: 100%;
}
.index_content__JWMCp > .index_infoContent__8CXTq .index_bottomSelectContent__pXnDm > span {
  font-size: 14px;
  display: block;
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
}
.index_content__JWMCp > .index_infoContent__8CXTq .index_bottomSelectContent__pXnDm > span:hover {
  background-color: #f5f5f5;
}
.index_content__JWMCp > .index_bottomContainer__iiOIp {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.index_content__JWMCp > .index_bottomContainer__iiOIp > .index_progressContent__Hhm_R {
  line-height: 1;
}
.index_content__JWMCp > .index_bottomContainer__iiOIp > .index_progressContent__Hhm_R > .index_desc__KjjJf {
  display: inline-block;
  font-size: 14px;
  color: #999;
  padding-bottom: 5px;
}
.index_content__JWMCp > .index_bottomContainer__iiOIp > .index_progressContent__Hhm_R > .index_progressStep__0uvim {
  display: flex;
}
.index_content__JWMCp > .index_bottomContainer__iiOIp > .index_progressContent__Hhm_R > .index_progressStep__0uvim > .index_indexDiv__vX7y9 {
  width: 50px;
  height: 5px;
  background: #eee;
  margin-left: 5px;
}
.index_content__JWMCp > .index_bottomContainer__iiOIp > .index_progressContent__Hhm_R > .index_progressStep__0uvim > .index_indexDiv__vX7y9:first-child {
  margin-left: 0;
}
.index_content__JWMCp > .index_bottomContainer__iiOIp > .index_progressContent__Hhm_R > .index_progressStep__0uvim > .index_indexDiv__vX7y9.index_active__NeB2B {
  background: var(--app-primary-color);
  margin-left: 0;
}
.index_content__JWMCp > .index_bottomContainer__iiOIp > .index_nextContainer__3EpaO {
  display: flex;
}
.index_content__JWMCp > .index_bottomContainer__iiOIp > .index_nextContainer__3EpaO > .index_preIcon__2esPl,
.index_content__JWMCp > .index_bottomContainer__iiOIp > .index_nextContainer__3EpaO > .index_nextIcon__7gthw {
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: var(--app-primary-color);
  color: var(--other-text-color);
  min-width: 44px;
  height: 44px;
  margin-right: 8px;
  padding: 0 5px;
}
.index_content__JWMCp > .index_bottomContainer__iiOIp > .index_nextContainer__3EpaO > .index_preIcon__2esPl:last-child,
.index_content__JWMCp > .index_bottomContainer__iiOIp > .index_nextContainer__3EpaO > .index_nextIcon__7gthw:last-child {
  margin-right: 0;
}
.index_content__JWMCp > .index_bottomContainer__iiOIp > .index_nextContainer__3EpaO > .index_nextView__gzKpw {
  width: 200px;
  padding: 0 20px;
}
.index_content__JWMCp > .index_bottomContainer__iiOIp > .index_nextContainer__3EpaO > .index_preIcon__2esPl {
  background: #222222;
}
.index_content__JWMCp > .index_bottomContainer__iiOIp > .index_nextContainer__3EpaO .index_disableBtn__gMCja {
  background: #f2f2f2;
  color: #999;
  cursor: auto;
}
.index_content__JWMCp > .index_bottomContainer__iiOIp > .index_nextContainer__3EpaO > .index_disable__TPx41 {
  background: #f2f2f2;
  color: #999;
  cursor: auto;
}
@media screen and (max-width: 768px) {
  .index_content__JWMCp {
    padding: 15px;
  }
  .index_content__JWMCp > .index_topTitle__D_aut {
    font-size: 20px;
    color: var(--app-text-color);
  }
  .index_content__JWMCp .index_bottomContainer__iiOIp .index_progressContent__Hhm_R .index_progressStep__0uvim .index_indexDiv__vX7y9 {
    width: 35px;
  }
  .index_content__JWMCp .index_bottomContainer__iiOIp .index_nextContainer__3EpaO .index_nextView__gzKpw {
    width: 100px;
  }
  .index_content__JWMCp > .index_topSubTitle__K9UC0 {
    margin-top: 15px;
    font-size: 16px;
  }
  .index_content__JWMCp > .index_topDesc__dKgh5 {
    margin-top: 10px;
    font-size: 14px;
  }
  .index_content__JWMCp > .index_infoContent__8CXTq {
    padding: 15px 0;
  }
  .index_content__JWMCp > .index_infoContent__8CXTq .index_selectContent__jLpBT {
    margin-bottom: 15px;
  }
}

:root {
  --foreground-rgb: #ffffff;
  --background-start-rgb: #ffffff;
  --background-end-rgb: #ffffff;
  --app-home-bg-rgb: #000;
  --app-line-bg-rgb: rgba(242, 242, 242, 0.3);
  --app-line-color: rgba(242, 242, 242, 0.3);
  --app-input-bg-rgb: #333;
  --app-home-gpt-bg-rgb: rgba(208, 58, 58, 0.1);
  --app-home-gpt-a-rgb: #2fba91;
  --app-share-bg-rgb: #333;
  --app-text-color: #141414;
  --app-text-60-color: rgba(20, 20, 20, 0.6);
  --app-text-30-color: rgba(20, 20, 20, 0.3);
  --other-text-color: #ffffff;
  --other-bg-color: #fff;
  --app-link-color: #2fba91;
  --app-primary-color: #0d6e4b;
  --app-primary-light-color: #2fba91;
}
:root .next-antd-rate-star-second {
  color: #666;
}
:root .linearSelect1 {
  background: linear-gradient(160deg, #725cff, #c2d0fb);
  border-radius: 12px;
  border: 0px;
}
:root .next-antd-float-btn-default .next-antd-float-btn-body {
  background-color: var(--app-primary-color);
}
:root .next-antd-float-btn-default .next-antd-float-btn-body .next-antd-float-btn-content .next-antd-float-btn-description {
  color: #fff;
}
:root .next-antd-modal-content {
  padding: 0px !important;
}

/* latin-ext */
@font-face {
  font-family: '__Poppins_36cd4a';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://res-front.pumpsoul.com/pb-test/_next/static/media/fe0777f1195381cb-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_36cd4a';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://res-front.pumpsoul.com/pb-test/_next/static/media/eafabf029ad39a43-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_36cd4a';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://res-front.pumpsoul.com/pb-test/_next/static/media/f10b8e9d91f3edcb-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_36cd4a';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://res-front.pumpsoul.com/pb-test/_next/static/media/8888a3826f4a3af4-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_36cd4a';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://res-front.pumpsoul.com/pb-test/_next/static/media/c3bc380753a8436c-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_36cd4a';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://res-front.pumpsoul.com/pb-test/_next/static/media/0484562807a97172-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_36cd4a';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://res-front.pumpsoul.com/pb-test/_next/static/media/a1386beebedccca4-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_36cd4a';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://res-front.pumpsoul.com/pb-test/_next/static/media/b957ea75a84b6ea7-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_36cd4a';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://res-front.pumpsoul.com/pb-test/_next/static/media/5fb25f343c7550ca-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_36cd4a';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://res-front.pumpsoul.com/pb-test/_next/static/media/7db6c35d839a711c-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Poppins_Fallback_36cd4a';src: local("Arial");ascent-override: 92.33%;descent-override: 30.78%;line-gap-override: 8.79%;size-adjust: 113.73%
}.__className_36cd4a {font-family: '__Poppins_36cd4a', '__Poppins_Fallback_36cd4a', system-ui, sans-serif;font-style: normal
}

